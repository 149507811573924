
import { defineComponent } from 'vue';
import store from "@/store/index";
import { Button, IconButton, Card, DataList } from '@/ui/index';
import { DepartmentController, WorkareaController } from '@/controller/';
import { Department } from '@/model/index.ts';
import { CardListToggleEdit } from "@/components";

export default defineComponent({
    name: "DepartmentDetail",
    components: { Button, IconButton, Card, DataList, CardListToggleEdit },
    data(){
        return {
            store,
            department: {} as Department,
            departmentWorkareas: [] as Array<any>,
            workareas: [] as Array<any>,
            workareaItemSettings: [
                { property: "number", dense: true, type: "text", formPlaceHolder: "0"},
                { property: "title", type: "text", fieldGrow: true, formPlaceHolder:"Neuer Arbeitsbereich"}
            ]
        }
    },
    created() {
        if (this.$route.params.id) {
            this.handleGetDepartment(this.$route.params.id);
        }
        this.handleGetWorkareasAll();
    },
    methods: {
        handleGoBack() {
            this.$router.go(-1);
        },
        handleEdit(){
            this.$router.push("/administration/department/form/" + this.department.uid);
        },
        async handleGetDepartment(uid:any) {
            const res = await DepartmentController.getDepartmentById(uid);
            if (!res.error) {
                this.department = res.department;
                this.handleGetWorkareasByDepartment();
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleGetWorkareasByDepartment() {
            const res = await WorkareaController.getWorkAreasByDepartment(this.department.uid);
            if(!res.error){
                this.departmentWorkareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleGetWorkareasAll() {
            const res = await WorkareaController.fetchWorkareas();
            if(!res.error){
                this.workareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleConfirmDeleteDepartment() {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteDepartmentText', { name: this.department.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(this.department.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await DepartmentController.deleteDepartment(uid);
            if (!res.error) {
                this.$notify(this.$t("success.departmentDeleted"), { position: "top" });
                this.$router.push("/administration/departments")
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleCreateWorkarea(formData:any) {
            const res = await WorkareaController.createWorkarea(formData, this.department.uid);
            if (!res.error) {
                this.$notify(this.$t("success.workareaCreated"), { position: "top" });
                this.departmentWorkareas.push(res.workarea);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleUpdateWorkarea(formData:any) {
            const res = await WorkareaController.updateWorkarea(formData, this.department.uid);
            if (!res.error) {
                this.departmentWorkareas = this.departmentWorkareas.map((item:any) =>{
                    if (item.uid == res.workarea.uid){
                        return res.workarea;
                    }
                    return item
                })
                this.$notify(this.$t("success.workareaUpdated"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleDeleteWorkarea(item : any) {
            const res = await WorkareaController.deleteWorkarea(item.uid);
            if (!res.error) {
                this.departmentWorkareas = this.departmentWorkareas.filter(function(item : any) {
                   return item.uid != res.workarea.uid;
                });
                this.$notify(this.$t("success.workareaDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    } 
})
